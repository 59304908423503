import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import _mock from '../../_mock';

import { MotionViewport, varFade } from '../../components/animate';
import React from 'react';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(20),
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  borderRadius: Number(theme.shape.borderRadius) * 2,
}));

const StyledDescription = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(15),
  },
}));

export default function HomeHugePackElements() {
  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Grid direction={{ xs: 'column', md: 'row-reverse' }} container spacing={5}>
          <Grid item xs={12} md={5}>
            <Description />
          </Grid>

          <Grid item xs={12} md={7}>
            <Content />
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

function Description() {
  return (
    <StyledDescription>
      <m.div variants={varFade().inUp}>
        <Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
          AI Portrait Studio
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ my: 3 }}>
          Create stunning <br />
          AI-generated portraits
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: 'text.secondary',
          }}
        >
          Upload your photos and let our AI generate beautiful, unique portraits. Customize styles and download high-quality results.
        </Typography>
      </m.div>
    </StyledDescription>
  );
}

// ----------------------------------------------------------------------

function Content() {
  return (
    <StyledContent>
      <img
        alt="screenshot mira"
        src="/assets/images/home/screenshot-mira.jpg"
        style={{ borderRadius: 20, boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.5)' }}
      />
    </StyledContent>
  );
}

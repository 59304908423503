import { Container, Stack, Box } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import { ReactNode } from 'react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useLocales from '@/locales/useLocales';
import useResponsive from '@/hooks/useResponsive';
import Headline, { SubHeadline } from '@/components/text/headlines';
import BigHomeButton from '@/components/buttons/homeMobile';

interface GridProps {
  children: ReactNode;
}

export default function BetaFree() {
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const isUnder400px = useResponsive('down', 400);

  // Calculate the top offset for the betaHome div
  const headerHeight = typeof document !== 'undefined' ? document.getElementById('header-main')?.clientHeight : 0;
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

  return (
    <>
      {/* Position the betaHome div relative to the header height */}
      <div id="betaHome" style={{ position: 'relative', top: `-${headerHeight}px` }} />

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <Stack spacing={isDesktop ? 6 : isUnder400px ? 3 : 6}>
          <Headline text={translate('home.betaFreeTitle')} sx={{ textAlign: 'center' }} />
          <SubHeadline text={translate('home.betaFreeDescription')} />
        </Stack>
        {innerHeight > 600 && isMobile && (
          <Stack spacing={3} sx={{ marginTop: 4, marginBottom: 4, textAlign: 'center' }}>
            <BigHomeButton text={translate('buttons.moreInfo')} url="#createHome" />
            <BigHomeButton text={translate('menu.signIn')} url={PATH_AUTH.login} />
          </Stack>
        )}
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function Grid({ children }: GridProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(3, 1fr)',
        md: 'repeat(6, 1fr)',
        lg: 'repeat(6, 1fr)',
      }}
      gap={2.5}
    >
      {children}
    </Box>
  );
}

import { useEffect, useRef, useState } from 'react';
import styles from './MagicWand.module.css';
import React from 'react';
import { gsap } from 'gsap';
import AppConfig from '@/config/config';
import useLocales from '@/locales/useLocales';

const allImages = Array.from({ length: 100 }, (_, i) =>
  AppConfig.version === 'dev'
    ? `/assets/images/homeExamples/${i + 1}.jpg`
    : `/cdn-cgi/image/width=600,quality=75/assets/images/homeExamples/${i + 1}.jpg`
);

const getRandomBalancedImages = (count: number) => {
  const images = new Set<number>();

  if (count === 2) {
    // Get 1 woman and 1 man
    images.add(Math.floor(Math.random() * 50) + 1);
    images.add(Math.floor(Math.random() * 50) + 51);
  } else if (count === 4) {
    // Get 2 women
    while (images.size < 2) {
      images.add(Math.floor(Math.random() * 50) + 1);
    }
    // Get 2 men
    while (images.size < 4) {
      images.add(Math.floor(Math.random() * 50) + 51);
    }
  } else {
    // count === 3
    const moreWomen = Math.random() < 0.5;
    // Get women (either 1 or 2)
    while (images.size < (moreWomen ? 2 : 1)) {
      images.add(Math.floor(Math.random() * 50) + 1);
    }
    // Get men (either 2 or 1)
    while (images.size < 3) {
      images.add(Math.floor(Math.random() * 50) + 51);
    }
  }

  return [...images]
    .sort(() => Math.random() - 0.5)
    .map((num) =>
      AppConfig.version === 'dev'
        ? `/assets/images/homeExamples/${num}.jpg`
        : `/cdn-cgi/image/width=600,quality=75/assets/images/homeExamples/${num}.jpg`
    );
};

const MagicWand = () => {
  const tilesRef = useRef<HTMLDivElement>(null);
  const [initialImages, setInitialImages] = useState<string[]>([]);
  const timeoutsRef = useRef<number[]>([]);

  useEffect(() => {
    const isMobilePhone = window.innerWidth <= 500;
    const tileCount = isMobilePhone ? 2 : window.innerWidth <= 800 ? 3 : 4;
    setInitialImages(getRandomBalancedImages(tileCount));

    const updateImage = (index: number) => {
      const imgContainer = tilesRef.current?.children[index] as HTMLElement;
      if (imgContainer) {
        const oldImg = imgContainer.querySelector('img') as HTMLImageElement;
        const currentImages = Array.from(tilesRef.current?.querySelectorAll('img') || []).map((img) => img.src);

        // Get new balanced set and find one that's not currently displayed
        let newImages = getRandomBalancedImages(tileCount);
        let newImgSrc;
        do {
          newImgSrc = newImages[Math.floor(Math.random() * newImages.length)];
        } while (currentImages.includes(newImgSrc));

        const newImg = document.createElement('img');
        newImg.src = newImgSrc;
        newImg.style.position = 'absolute';
        newImg.style.top = '0';
        newImg.style.left = '0';
        newImg.style.width = '100%';
        newImg.style.height = '100%';
        newImg.style.objectFit = 'cover';
        newImg.style.opacity = '0';

        imgContainer.appendChild(newImg);

        // Create a background div for the "hole" effect
        const bgDiv = document.createElement('div');
        bgDiv.style.position = 'absolute';
        bgDiv.style.top = '0';
        bgDiv.style.left = '0';
        bgDiv.style.width = '100%';
        bgDiv.style.height = '100%';
        bgDiv.style.background = 'radial-gradient(circle at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 70%)';
        bgDiv.style.borderRadius = '3vmin';
        bgDiv.style.opacity = '0';
        imgContainer.appendChild(bgDiv);

        // Animate the transition
        const tl = gsap.timeline();

        tl.to(oldImg, { opacity: 0, duration: 0.3 }, 0)
          .to(bgDiv, { opacity: 1, duration: 0.3 }, 0)
          .to(bgDiv, {
            background: 'radial-gradient(circle at center, rgba(255,255,255,0.8) 100%, rgba(0,0,0,0) 100%)',
            duration: 0.3,
            ease: 'power2.in',
          })
          .to(newImg, { opacity: 1, duration: 0.3 })
          .to(bgDiv, { opacity: 0, duration: 0.3 }, '-=0.3')
          .call(() => {
            oldImg.remove();
            bgDiv.remove();
          });
      }
    };

    const startUpdating = (index: number) => {
      const randomTime = Math.random() * (7000 - 3000) + 3000;
      const timeout = window.setTimeout(() => {
        updateImage(index);
        startUpdating(index);
      }, randomTime);
      timeoutsRef.current[index] = timeout;
    };

    Array.from({ length: tileCount }).forEach((_, index) => startUpdating(index));

    return () => {
      // Clear all timeouts when the component unmounts
      timeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  const { translate } = useLocales();

  return (
    <div id="tiles" className={styles['tiles']} ref={tilesRef}>
      {initialImages.map((src, index) => (
        <div
          key={index}
          className={`${styles['tile']} tile`}
          style={{ transition: 'all 0.3s ease-in-out', overflow: 'hidden', position: 'relative' }}
        >
          <img src={src} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          <div
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              background: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '3vmin',
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.5px',
              backdropFilter: 'blur(4px)',
              textTransform: 'uppercase',
              zIndex: 10,
            }}
          >
            {translate('AIImageComparison.generated')}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MagicWand;

import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
// components
import { useSettingsContext } from '../../components/settings';
import Image from '../../components/image';
import { MotionViewport, varFade } from '../../components/animate';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useLocales from '@/locales/useLocales';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

export default function HomeColorPresets() {
  return (
    <StyledRoot>
      <Container component={MotionViewport} sx={{ position: 'relative' }}>
        <Description />
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

function Description() {
  const { translate } = useLocales();
  // Ensure document is defined before accessing it
  const headerHeight = typeof document !== 'undefined' ? document.getElementById('header-main')?.clientHeight : 0;

  return (
    <>
      <div id="homeEnd" style={{ position: 'relative', top: `-${headerHeight ? headerHeight + 30 : 30}px` }} />

      <Stack spacing={3} sx={{ textAlign: 'center' }}>
        <m.div variants={varFade().inDown}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              letterSpacing: 0.5,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              fontWeight: 200,
            }}
          >
            {translate('home.homeColorPresetsTitle')}
          </Typography>
        </m.div>

        <m.div variants={varFade().inDown}>
          <Typography variant={isMobile ? 'h4' : 'h2'}>{translate('home.homeColorPresetsDescription')}</Typography>
        </m.div>

        <m.div variants={varFade().inDown}>
          <Typography
            variant={isMobile ? 'body1' : 'h4'}
            sx={{
              textAlign: 'center',
              letterSpacing: 0.2,
              fontWeight: 200,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            {translate('home.homeColorPresetsSubDescription')}
          </Typography>{' '}
        </m.div>
      </Stack>
    </>
  );
}
